<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <a-form-model :rules="rules" :form="form" ref="ruleForm" :model="form" @ok="handleOk">
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item ref="customerId" prop="customerId" label="客户名称">
              <a-select v-model="form.customerId" style="width: 100%" placeholder="请选择客户" 
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;}" show-search
                :default-value="null" option-label-prop="label">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="sellerId" prop="sellerId" label="卖方主体">
              <a-select v-model="form.sellerId" style="width: 100%" placeholder="请选择卖方主体">
                <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item ref="invoiceAmount" prop="invoiceAmount" label="合计开票金额">
              <div style="position: relative;">
                <a-input v-model="form.invoiceAmount" placeholder="请输入合计开票金额" @blur="handleBlur" />
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="合计业务支出" ref="businessExpenses" prop="businessExpenses">
              <div style="position: relative;">
                <a-input v-model="form.businessExpenses" placeholder="请输入合计业务支出" @blur="handleBlur" />
                <span style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);">元</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item label="预期出库时间">
              <a-date-picker v-if="this.$route.query.pageType=='copy'" style="width: 100%;" @change="dateChange" :format="dateFormat" />
                <a-date-picker v-else style="width: 100%;" @change="dateChange"
                :default-value="moment(formatDate(form.sellingTime), dateFormat)" :format="dateFormat" />
            </a-form-model-item>
          </a-col>
          <a-col :xl="{ span: 7, offset: 2 }" :lg="{ span: 8 }" :md="{ span: 12 }" :sm="24">
            <a-form-model-item label="预计回款时间">
              <a-date-picker v-if="this.$route.query.pageType=='copy'" style="width: 100%;" @change="hkChange" :format="dateFormat" />
                <a-date-picker v-else style="width: 100%;" @change="hkChange"
                :default-value="moment(formatDate(form.expectReturnTime), dateFormat)" :format="dateFormat" />
            </a-form-model-item>
          </a-col>
          </a-row>
          <a-row>
            <a-col  :lg="6" :md="12" :sm="24">
            <div class="price-box">
              <div style="border-right: 1px solid #EBEAED;">
                <p>合计实际收款</p>
                <span>{{ getAmount() }}元</span>
              </div>
             <div>
               <p>平均折扣:</p>
               <span v-if="form.averageDiscount">{{ form.averageDiscount }}</span> <span v-else>0</span> 折
             </div>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <card ref="myCard" :totalAmount="form.amount" @handleObject="handleObject" @handleDiscount="handleDiscount" type="edit" :detailsArr="form.sfaSaleDocketProductList" :isBackShow="false"></card>

    <footer-tool-bar>
      <a-button type="primary" style="margin-right: 20px;background: none;border: 1px solid #999999;color: #999999;"
        @click="handleOk(1)" :loading="loading">{{ $t('存草稿') }}</a-button>
      <a-button type="primary" @click="handleOk(4)" :loading="loading">{{ $t('提交审核') }}</a-button>
    </footer-tool-bar>
  </div>
</template>
  
<script>
import moment from 'moment';
import { request } from '@/utils/request'
import FooterToolBar from '@/components/tool/FooterToolBar'
import card from './components/card.vue'
export default {
  name: 'addForm',
  components: { FooterToolBar, card },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      loading: false,
      form: {
        invoiceAmount: '',
        businessExpenses: '',
        amount: ''
      },

      users: [],
      rules: {
        customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
        sellerId: [{ required: true, message: '请选择卖方主体', trigger: 'change' }],
        invoiceAmount: [{ required: true, message: '请输入合计开票金额', trigger: 'blur' }],
        businessExpenses: [{ required: true, message: '请输入合计业务支出', trigger: 'blur' }],
        sellingTime: [{ required: true, message: '请选择预期出库时间', trigger: 'change' }],
      },
      describeText: "",
      details: {},
      customerData: [],
      sellerList: [],//卖方主体
      expectReturnTime:""
    }
  },
  mounted() {
    // 详情
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/' + this.$route.query.id, 'get').then(res => {
      this.form = res.data.data;
      // 复制：默认不展示售卡日期
      if(this.$route.query.pageType == 'copy') {
        this.form.sellingTime = ""
      }
      // let invoiceAmount = 0;
      // 复制默认不展示每条激活、冻结等状态
      this.form.sfaSaleDocketProductList.forEach(item => {
        item.sfaCardCouponList.forEach(it => {
          if(this.$route.query.pageType == 'copy') {
              delete it.outBatchCode
              delete it.actStatus
              delete it.actStatusName
              delete it.frozenStatusName
              delete it.frozenStatus
              delete it.outStatus
              delete it.outStatusName
          }
          // invoiceAmount = invoiceAmount + (it.amount * it.number)
          // this.form.invoiceAmount = invoiceAmount;
        })
        console.log(this.form,'form')
      })
    })
    // 客户列表
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list/all', 'get').then(res => {
      this.customerData = res.data.data
    })
    // 卖方主体
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
      this.sellerList = res.data.data;
    })
  },
  methods: {
    handleBlur(){
      this.$refs.myCard.calcDiscount();
    },
    getAmount(){ 
      // businessExpenses业务支出     invoiceAmount 开票金额
      this.form.amount = this.form.invoiceAmount - this.form.businessExpenses
      return this.form.invoiceAmount - this.form.businessExpenses
    },
    handleDiscount(discount){
      this.form.averageDiscount = discount
      this.$set(this.form,'averageDiscount', discount);
      console.log(this.form.averageDiscount,'aver')
    },
    moment,
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split('T')[0];
    },
    hkChange(date,dateString) {
      this.form.expectReturnTime = dateString;
      this.expectReturnTime = dateString
    },
    // 选择日期
    dateChange(date, dateString) {
      this.form.sellingTime = dateString
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    back() {
      this.$router.replace('/sfa_workOrder/OutboundList')
    },
    handleObject(data) {
      let amount = 0;
      data.forEach(item => {
        if (item.sfaCardCouponList.length > 0) {
          item.sfaCardCouponList.forEach(it => {
            amount = amount + (it.amount * it.number)
          })
        } 
        this.form.amount = amount;
      })
      this.form.sfaSaleDocketProductList = data;
      console.log(this.form,'data')
    },
    handleOk(status) {
      this.form.status = status;
      
      if (!this.form.sfaSaleDocketProductList || this.form.sfaSaleDocketProductList.length == 0) {
        this.$message.error('请添加卡券！')
        return false;
      }
      if (!this.form.sellingTime) {
        this.$message.error('请选择预期出库时间！')
        return false;
      }
      if(!this.form.expectReturnTime) {
        this.$message.error('请选择预计回款时间！')
        return false;
      }
      let msg = '更新成功！'
      if (this.$route.query.pageType == 'copy') {
        delete this.form.id
        msg = '复制成功！'
      }
      if(this.form.sfaSaleDocketProductList[0].sfaCardCouponList.length == 0&& this.form.sfaSaleDocketProductList[1].sfaCardCouponList.length == 0 && this.form.sfaSaleDocketProductList[2].sfaCardCouponList.length == 0) {
        this.$message.error('请添加卡券！')
        return false;
      }
      this.form.sfaSaleDocketProductList.forEach(item => {
        delete item.id
      })
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.sfaSaleDocketProductList.forEach((item) => {
            item.sfaCardCouponList.forEach(it => {
              // 编辑、复制删除每条id
              delete it.style;
            });
          });
          this.$message.loading();
          console.log( this.form,' this.form')
          setTimeout(() => {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/saledocket/save', 'post', this.form).then(res => {
              this.$message.destroy()
              if (res.data.code == 200) {
                this.$message.success(msg);
                this.$router.replace('/sfa_workOrder/OutboundList')
              } else {
                this.$message.error(res.data.message);
              }
            })
          }, 100)
        } else {
          return false;
        }
      });

    },

  }
}
</script>
  
<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

::v-deep .ant-card-body {
  padding: 24px !important;
}

.price-box {
  display: flex;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(85, 66, 246, 0.03);
}

.price-box div {
  padding: 10px 0;
  box-sizing: border-box;
  flex: 1;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #010101;
  text-align: center;
}

.price-box span {
  font-size: 18px;
  color: rgba(255, 0, 0, 1);
}


// ::v-deep .ant-input[disabled] {
//   background: #fff;
//   color: #333;
// }
</style>
